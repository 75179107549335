<template>
  <div id="modal-template">
    <div class="modal-wrapper">
        <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyModal",
  mounted() {
    let modal =  document.querySelector('.modal-mask');
    modal.style.display = "block";
    modal.append(this.$el);
    document.querySelector('body').style.overflow = 'hidden';
    let modalCount = localStorage.getItem('modal')
    localStorage.setItem('modal', parseInt(modalCount)+1)
  },
  destroyed() {
    let modalCount = localStorage.getItem('modal')
    localStorage.setItem('modal', modalCount-1)
    if(modalCount == 1){
      let modal =  document.querySelector('.modal-mask');
      modal.style.display = "none";
      document.querySelector('body').style.overflow = 'auto';
    }
  }
};
</script>
<template>
  <my-modal>
    <div class="modal-dialog modal-lg card">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Previsualizar recibo</h5>
          <button type="button" class="close" @click="cerrar">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              class="
                order-2
                col-12 col-md-6
                d-flex
                align-items-start
                justify-content-center
              "
            >
              <div
                class="w-100"
                style="
                  box-sizing: border-box;
                  padding: 15px;
                  background: grey;
                  max-height: 82vh;
                  min-height: 500px;
                  overflow: auto;
                "
              >
                <pdf
                  v-if="src && !cargandoRecibo"
                  ref="pdf"
                  :src="src"
                  :page="page"
                  :rotate="rotate"
                  :style="'width:calc(100% + ' + width + 'px );'"
                ></pdf>
                <div
                  class="pdf-loader"
                  v-if="cargandoRecibo && !src"
                  style="
                    background: white;
                    height: 482px;
                    width: 340px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                  <h3>Cargando recibo.</h3>
                  <h5>Por favor, espere.</h5>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 order-1">
              <div class="row">
                <!-- <div class="col-12 text-center">
                  <h4>Previsualizando recibo de:</h4>
                  <h5>ARATA, ANALIA GABRIELA</h5>
                </div> -->
                <div class="col-6 my-2">
                  <button
                    class="btn btn-outline-success btn-wide btn-block"
                    @click="width += 50"
                  >
                    Acercar <i class="fa fa-search-plus"></i>
                  </button>
                </div>
                <div class="col-6 my-2">
                  <button
                    class="btn btn-outline-danger btn-wide btn-block"
                    @click="width -= 50"
                  >
                    Alejar <i class="fa fa-search-minus"></i>
                  </button>
                </div>
                <div class="col-12 my-2">
                  <button
                    class="btn btn-primary btn-wide btn-block"
                    @click="emitir"
                  >
                    Imprimir recibo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </my-modal>
</template>
<script>
import MyModal from "@/components/layouts/MyModal";
import Axios from "axios";
import pdf from "vue-pdf";
export default {
  name: "ReciboPreview",
  components: {
    MyModal,
    pdf,
  },
  data() {
    return {
      src: "",
      loadedRatio: 0,
      page: 1,
      width: 0,
      numPages: 0,
      rotate: 0,
      cargandoRecibo: false,
    };
  },
  props: {
    recibo: {
      value: {},
      required: true,
    },
    readonly: null,
  },
  mounted() {
    this.getRecibo();
  },
  methods: {
    getRecibo() {
      this.cargandoRecibo = true;
      let url = "";
      if (!this.recibo.id && this.readonly) {
        url = "recibos/preview/";
        Axios.post(
          url,
          { recibo: this.recibo },
          {
            responseType: "arraybuffer",
          }
        )
          .then((res) => {
            this.src = res;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.cargandoRecibo = false;
          });
      } else {
        if (this.recibo.estado == "pendiente") {
          url = "recibos/preview/";
        } else {
          url = "recibos/impresion/";
        }
        Axios.get(url + this.recibo.id, {
          responseType: "arraybuffer",
        })
          .then((res) => {
            this.src = res;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.cargandoRecibo = false;
          });
      }
    },
    emitir() {
      if (this.readonly) {
        this.openPDF();
      } else {
        if (this.recibo.estado == "pendiente") {
          this.cargandoRecibo = true;
          let url = "recibos/emitir/";
          this.src = null;
          Axios.get(url + this.recibo.id, {
            responseType: "arraybuffer",
          })
            .then((res) => {
              this.src = res;
              this.openPDF();
            })
            .catch((err) => console.log(err))
            .finally(() => {
              this.cargandoRecibo = false;
            });
        } else {
          this.openPDF();
        }
      }
      this.$emit("cerrar");
    },
    password: function (updatePassword, reason) {
      updatePassword(prompt('password is "test"'));
    },
    error: function (err) {
      console.log(err);
    },
    cerrar() {
      this.$emit("cerrar");
    },
    openPDF() {
      let url = Axios.defaults.baseURL + "/recibos/imprimir/"+btoa(this.recibo.id);
      var fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.target = "_blank";
      fileLink.click();
    },
  },
};
</script>